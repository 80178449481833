import React from "react";

function MasjidInfo({
  timings,
  nextChanges,
  announcements,
  details,
  username,
}) {
  const noNextChanges = {
    fajr: ["anjumanmasjid"],
    asr: [],
    isha: [],
  };

  function adjustTime(timeStr, minutesToAdd) {
    let [hours, minutes] = timeStr.split(":").map(Number);
    minutes += minutesToAdd;

    while (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }

    while (minutes >= 60) {
      hours += 1;
      minutes -= 60;
    }

    hours = (hours + 24) % 24;

    return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
  }

  function twentyFourToTwelve(time) {
    let parts = time.split(":");
    let hours = parts[0];
    let minutes = parts[1];
    hours = hours % 12;
    hours = hours ? hours : 12;
    if (hours < 10) {
      hours = "0" + hours;
    }
    return hours + ":" + minutes;
  }

  const Months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  function formatDate(day, month) {
    return `${day} ${Months[month]}`;
  }

  return (
    <>
      <div className="d-flex justify-content-center" style={{ marginTop: 24 }}>
        <div
          className="card"
          style={{
            width: "95%",
            background: "transparent",
            borderRadius: 24,
            color: "#333333",
            borderStyle: "none",
          }}
        >
          <div className="card-body">
            <h3 className="card-title" style={{ textAlign: "center" }}>
              {details.engName}
            </h3>
            <h3 className="card-subtitle mb-2" style={{ textAlign: "center" }}>
              {details.urdName}
            </h3>
            <p className="card-text" style={{ textAlign: "center" }}>
              {details.address}
            </p>
          </div>
        </div>
      </div>

      {announcements.length > 0 && (
        <div
          className="d-flex d-lg-flex justify-content-center justify-content-lg-center"
          style={{ marginTop: 24 }}
        >
          <div
            className="card text-center"
            style={{
              width: "95%",
              background: "#333333",
              borderRadius: 24,
              color: "#ffffff",
            }}
          >
            <div className="card-body">
              <h4 className="card-title" style={{ textAlign: "center" }}>
                Announcements
              </h4>
              <h4
                className="card-subtitle mb-2"
                style={{ textAlign: "center" }}
              >
                اعلانات
              </h4>

              {announcements.map((ann, index) => (
                <div key={index} className="row" style={{ marginTop: 24 }}>
                  <div className="col">
                    <p style={{ marginBottom: 0, textAlign: "left", paddingLeft: "10px" }}>{ann.englishText}</p>
                  </div>
                  <div className="col">
                    <p style={{ marginBottom: 0, textAlign: "right", paddingRight: "10px" }}>{ann.urduText}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div
        className="d-flex d-lg-flex justify-content-center justify-content-lg-center"
        style={{ marginTop: 24 }}
      >
        <div
          className="card text-center"
          style={{
            width: "95%",
            background: "#333333",
            borderRadius: 24,
            color: "#ffffff",
          }}
        >
          <div className="card-body">
            <h4 className="card-title" style={{ textAlign: "center" }}>
              Jamaat Timings Today
            </h4>
            <h4 className="card-subtitle mb-2" style={{ textAlign: "center" }}>
              آج جماعت کے اوقات
            </h4>
            <div className="row" style={{ marginTop: 24 }}>
              <div className="col">
                <h5>Fajr</h5>
              </div>
              <div className="col">
                <h5>{twentyFourToTwelve(adjustTime(timings.fajrJamaat, 0))}</h5>
              </div>
              <div className="col">
                <h5>فجر</h5>
              </div>
            </div>
            <div className="row" style={{ marginTop: 12 }}>
              <div className="col">
                <h5>Zuhr</h5>
              </div>
              <div className="col">
                <h5>{twentyFourToTwelve(adjustTime(timings.zuhrJamaat, 0))}</h5>
              </div>
              <div className="col">
                <h5>ظہر</h5>
              </div>
            </div>
            <div className="row" style={{ marginTop: 12 }}>
              <div className="col">
                <h5>Asr</h5>
              </div>
              <div className="col">
                <h5>{twentyFourToTwelve(adjustTime(timings.asrJamaat, 0))}</h5>
              </div>
              <div className="col">
                <h5>عصر</h5>
              </div>
            </div>
            <div className="row" style={{ marginTop: 12 }}>
              <div className="col">
                <h5>Maghrib</h5>
              </div>
              <div className="col">
                <h5>
                  {twentyFourToTwelve(adjustTime(timings.maghribJamaat, 0))}
                </h5>
              </div>
              <div className="col">
                <h5>مغرب</h5>
              </div>
            </div>
            <div className="row" style={{ marginTop: 12 }}>
              <div className="col">
                <h5>Isha</h5>
              </div>
              <div className="col">
                <h5>{twentyFourToTwelve(adjustTime(timings.ishaJamaat, 0))}</h5>
              </div>
              <div className="col">
                <h5>عشاء</h5>
              </div>
            </div>
            {/* Horizontal line */}
            <div
              className="row"
              style={{
                border: "1px solid white",
                margin: "12px 0",
              }}
            ></div>
            <div className="row" style={{ marginTop: 12 }}>
              <div className="col">
                <h5>Jumah</h5>
              </div>
              <div className="col">
                <h5>
                  {twentyFourToTwelve(adjustTime(timings.jumahJamaat, 0))}
                </h5>
              </div>
              <div className="col">
                <h5>جمعہ</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex d-lg-flex justify-content-center justify-content-lg-center"
        style={{ marginTop: 24 }}
      >
        <div
          className="card text-center"
          style={{
            width: "95%",
            background: "#333333",
            borderRadius: 24,
            color: "#ffffff",
          }}
        >
          <div className="card-body">
            <h4 className="card-title" style={{ textAlign: "center" }}>
              Changes in Jamaat Timings
            </h4>
            <h4 className="card-subtitle mb-2" style={{ textAlign: "center" }}>
              جماعت آئندہ تبدیلی
            </h4>

            {!noNextChanges.fajr.includes(username) && (
              <div className="row" style={{ marginTop: 24 }}>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>Fajr</h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {twentyFourToTwelve(nextChanges.fajrJamaat.time)}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {formatDate(
                      nextChanges.fajrJamaat.day,
                      nextChanges.fajrJamaat.month
                    )}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>فجر</h6>
                </div>
              </div>
            )}
            {!noNextChanges.asr.includes(username) && (
              <div className="row" style={{ marginTop: 24 }}>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>Asr</h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {twentyFourToTwelve(nextChanges.asrJamaat.time)}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {formatDate(
                      nextChanges.asrJamaat.day,
                      nextChanges.asrJamaat.month
                    )}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>عصر</h6>
                </div>
              </div>
            )}
            {!noNextChanges.isha.includes(username) && (
              <div className="row" style={{ marginTop: 24 }}>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>Isha</h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {twentyFourToTwelve(nextChanges.ishaJamaat.time)}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>
                    {formatDate(
                      nextChanges.ishaJamaat.day,
                      nextChanges.ishaJamaat.month
                    )}
                  </h6>
                </div>
                <div className="col">
                  <h6 style={{ marginBottom: 0 }}>عشاء</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MasjidInfo;
