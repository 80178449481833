import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MasjidInfo from "./MasjidInfo";

function MasjidDetails() {
  const { username } = useParams();
  const [search, setSearch] = useState("");
  const [masjidInfo, setMasjidInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const fetchMasjidInfo = async () => {
      try {
        const headers = {
          Authorization: username,
          "Content-Type": "application/json",
        };

        const [
          detailsResponse,
          timingsTodayResponse,
          timingsNextChangesResponse,
          announcementsResponse,
        ] = await Promise.all([
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/masjid/details`, {
            headers,
          }),
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/timings/today`, {
            headers,
          }),
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/timings/next-changes`,
            { headers }
          ),
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/announcements/current`,
            { headers }
          ),
        ]);

        const masjidDetails = await detailsResponse.json();
        const timingsToday = await timingsTodayResponse.json();
        const timingsNextChanges = await timingsNextChangesResponse.json();
        const announcements = await announcementsResponse.json();

        setMasjidInfo({
          details: masjidDetails,
          timings: timingsToday,
          nextChanges: timingsNextChanges,
          announcements: announcements.currentAnnouncements,
        });
      } catch (error) {
        console.error("Failed to fetch detailed data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMasjidInfo();
  }, [username]);

  const handleSearch = () => {
    if (search.trim()) {
      navigate("/search-results", { state: { search } });
    }
  };

  return (
    <>
      <div className="container-fluid full-width-container d-flex justify-content-center align-items-center">
        <h1 style={{ textAlign: "center", color: "#ffffff" }}>
          <strong>MIS</strong> Masajid Information
        </h1>
      </div>

      {!isOnline && (
        <div style={{ marginTop: 64, textAlign: "center" }}>
          <h1 style={{ color: "#c40e0e", marginTop: 64 }}>
            Please connect to the internet
          </h1>
        </div>
      )}

      {isOnline && (
        <div
          className="d-flex d-md-flex justify-content-center justify-content-md-center"
          style={{ marginTop: 24 }}
        >
          <div
            className="input-group"
            style={{
              width: "95%",
              background: "#ffffff",
              borderRadius: 24,
              border: "1px solid #333333",
            }}
          >
            <input
              className="form-control py-2 rounded-pill mr-1 pr-5 search-input"
              type="search"
              style={{ background: "transparent", borderStyle: "none" }}
              placeholder="Masjid Name in English/Urdu"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <span
              className="input-group-text input-group-append"
              style={{
                background: "#333333",
                borderStyle: "none",
                borderRadius: 24,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                borderBottomLeftRadius: 0,
              }}
            >
              <button
                className="btn rounded-pill border-0 ml-n5"
                type="button"
                onClick={handleSearch}
              >
                <i className="fas fa-search" style={{ color: "#ffffff" }} />
              </button>
            </span>
          </div>
        </div>
      )}

      {loading && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 24 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {masjidInfo && !loading && (
        <MasjidInfo
          timings={masjidInfo.timings}
          nextChanges={masjidInfo.nextChanges}
          announcements={masjidInfo.announcements}
          details={masjidInfo.details}
          username={username}
        />
      )}
    </>
  );
}

export default MasjidDetails;